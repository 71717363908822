<template>
  <div>
    <v-row v-if="isAdmin && Boolean(client)">
      <v-spacer></v-spacer>
      <v-switch
        v-model="editMode"
        label="Edit Mode"
        dense
        class="mx-4"
      ></v-switch>
    </v-row>
    <v-card outlined>
      <v-card-title>
        <v-icon class="mr-2">mdi-account-outline</v-icon> Profile
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="2">
            <validation-provider rules="required" v-slot="{ errors }">
              <v-text-field
                v-model="form.firstName"
                label="First Name"
                :readonly="!editMode"
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="1">
            <v-text-field
              v-model="form.middleInitial"
              label="MI"
              :readonly="!editMode"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <validation-provider rules="required" v-slot="{ errors }">
              <v-text-field
                v-model="form.lastName"
                label="Last Name"
                :readonly="!editMode"
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="2">
            <v-select
              v-model="form.gender"
              :items="genders"
              label="Gender"
              :readonly="!editMode"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3">
            <div class="pt-4">
              <date-picker
                :field="{ label: 'Date Of Birth' }"
                :value="form.dateOfBirth"
                :validations="{ required: true, maxDate: new Date() }"
                :readonly="!editMode"
                @fieldChange="form.dateOfBirth = $event"
              >
              </date-picker>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3"
            ><v-text-field
              v-model="form.osis"
              label="OSIS"
              v-mask="maskOsis"
              :readonly="!editMode"
            ></v-text-field
          ></v-col>
          <v-col cols="12" sm="2"
            ><v-text-field
              v-model="form.nyeisId"
              label="NYEIS ID"
              :readonly="!editMode"
            ></v-text-field
          ></v-col>
          <v-col cols="12" sm="4">
            <v-autocomplete
              :items="physicians"
              v-model="form.referringPhysicianId"
              item-text="displayName"
              item-value="physicianId"
              label="Referring Physician"
              :readonly="!editMode"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3">
            <v-autocomplete
              :items="languages"
              v-model="form.defaultLanguageId"
              item-text="name"
              item-value="languageId"
              label="Default Language"
              :readonly="!editMode"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-title>
        <v-icon class="mr-2">mdi-map-marker-outline</v-icon> Address
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="form.streetAddress"
              label="Street Address"
              :readonly="!editMode"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="form.streetAddressLine2"
              label="Street Address Line 2"
              :readonly="!editMode"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field
              v-model="form.city"
              label="City"
              :readonly="!editMode"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <v-select
              :items="usStates"
              v-model="form.state"
              label="State"
              :readonly="!editMode"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="1">
            <v-text-field
              v-model="form.zipCode"
              label="ZipCode"
              :readonly="!editMode"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="1">
            <v-text-field
              v-model="form.districtId"
              label="District"
              :readonly="!editMode"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-title>
        <v-icon class="mr-2">mdi-phone-outline</v-icon> Contact
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="form.emailAddress"
              label="Email Address"
              :readonly="!editMode"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field
              v-model="form.homePhone"
              label="Home Phone"
              v-mask="maskPhone"
              :readonly="!editMode"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field
              v-model="form.mobilePhone"
              label="Mobile Phone"
              v-mask="maskPhone"
              :readonly="!editMode"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field
              v-model="form.workPhone"
              label="Work Phone"
              v-mask="maskPhone"
              :readonly="!editMode"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <v-select
              v-model="form.preferredPhone"
              :items="phoneTypes"
              label="Preferred Phone"
              :readonly="!editMode"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="form.caregiverFirstName"
              label="Caregiver First Name"
              :readonly="!editMode"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="form.caregiverLastName"
              label="Caregiver Last Name"
              :readonly="!editMode"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              v-model="form.caregiverRelationship"
              :items="caregiverRelationships"
              label="Caregiver Relationship"
              :readonly="!editMode"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-title>
        <v-checkbox
          v-model="form.eiClient"
          label="EI Client"
          :readonly="!editMode"
        ></v-checkbox>
      </v-card-title>
      <div v-if="form.eiClient">
        <v-card-title>
          <v-icon class="mr-2">mdi-chevron-triple-right</v-icon> Early
          Intervention
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="3">
              <div class="pt-4">
                <date-picker
                  :field="{ label: 'Referral Date' }"
                  :value="form.eiClient.eiReferralDate"
                  :validations="{
                    required: Boolean(form.eiClient),
                    maxDate: new Date(),
                  }"
                  :readonly="!editMode"
                  @fieldChange="form.eiClient.eiReferralDate = $event"
                >
                </date-picker>
              </div>
            </v-col>
            <v-col cols="12" sm="3">
              <div class="pt-4">
                <date-picker
                  :field="{ label: 'Inital Date' }"
                  :value="form.eiClient.eiInitialDate"
                  :validations="{
                    required: Boolean(form.eiClient),
                    maxDate: new Date(),
                  }"
                  :readonly="!editMode"
                  @fieldChange="form.eiClient.eiInitialDate = $event"
                >
                </date-picker>
              </div>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                v-model="form.eiClient.caseNumber"
                label="Case Number"
                :readonly="!editMode"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                v-model="form.eiClient.eiStaffingTeam"
                :items="eiStaffingTeams"
                label="EI Staffing Team"
                :readonly="!editMode"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="1">
              <v-checkbox
                v-model="form.eiClient.active"
                label="Active"
                :readonly="!editMode"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel" text>Cancel</v-btn>
        <v-btn @click="save" disabled color="primary">Save</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Client from "../../objs/Client";
export default {
  props: ["client"],
  data() {
    return {
      maskPhone: "(###) ###-####",
      maskOsis: "###-###-###",
      editMode: !Boolean(this.client),
      form: new Client(),
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["securityModule/isAdmin"];
    },
    genders() {
      return this.$store.state.optionsModule.genders;
    },
    physicians() {
      return this.$store.state.optionsModule.physicians;
    },
    languages() {
      return this.$store.state.optionsModule.languages;
    },
    usStates() {
      return this.$store.state.optionsModule.usStates;
    },
    phoneTypes() {
      return this.$store.state.optionsModule.phoneTypes;
    },
    eiStaffingTeams() {
      return this.$store.state.optionsModule.eiStaffingTeams;
    },
    caregiverRelationships() {
      return this.$store.state.optionsModule.caregiverRelationships;
    },
  },
  mounted() {
    if (this.client) {
      this.form = { ...this.client };
    } else {
      // if new client, clear selected client from store
      this.$store.dispatch("clientModule/clearClient");
    }
  },
  methods: {
    cancel() {
      this.$router.push({ name: "ClientLookup" });
    },
    async save() {
      let success = false;
      if (this.client) {
        success = await this.$store.dispatch(
          "clientModule/updateClient",
          this.form
        );
      } else {
        success = await this.$store.dispatch(
          "clientModule/createClient",
          this.form
        );
      }
      if (success) {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Client saved successfully"
        );
      } else {
        this.$store.commit("uxModule/setSnackbarMsg", "Error saving client");
      }
      this.$store.commit("uxModule/setShowSnackbar", true);
    },
  },
};
</script>
