<template>
  <v-row>
    <v-col cols="12" sm="5">
      <v-row>
        <v-col cols="12" sm="8">
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-switch
            v-model="unsignedOnly"
            :label="
              isServiceCoordination
                ? 'Awaiting Signature'
                : 'Awaiting Supervisor Signature'
            "
          ></v-switch> </v-col
      ></v-row>
      <download-zip-button
        :selectedFiles="
          selectedItems.map((item) =>
            isServiceCoordination ? item.scNoteFileId : item.sessionNoteFileId
          )
        "
        :zipFileName="zipFileName"
        @clear="clearSelected"
      ></download-zip-button>
      <v-data-table
        v-model="selectedItems"
        :headers="_headers"
        :items="_notes"
        item-key="noteId"
        :search="search"
        :group-by="_groupBy"
        @click:row="loadFile"
        dense
        height="100%"
        style="cursor: pointer"
        :item-class="highlightSelectedRow"
        show-select
        :key="_notes.length"
      >
        <template v-slot:[`header.data-table-select`]></template>
        <template
          v-slot:[`group.header`]="{ group, headers, toggle, isOpen, items }"
          ><td :colspan="headers.length">
            <v-btn @click="toggle" x-small icon :ref="group">
              <v-icon v-if="isOpen">mdi-minus</v-icon>
              <v-icon v-else>mdi-plus</v-icon>
            </v-btn>
            <span class="mx-5 font-weight-bold text-subtitle-2">
              {{
                clientId
                  ? isServiceCoordination
                    ? items[0].formattedDateOfService
                    : items[0].serviceName
                  : items[0].clientName
              }}
            </span>
          </td>
        </template>
        <template v-slot:[`item.scSignatureId`]="{ item }">
          <v-icon v-if="item.scSignatureId" color="success"
            >mdi-check-box-outline</v-icon
          >
          <v-icon v-else>mdi-signature</v-icon>
        </template>
        <template v-slot:[`item.supervisorSignatureId`]="{ item }">
          <v-icon
            v-if="
              item.requiresSupervisorSignature && item.supervisorSignatureId
            "
            color="success"
            >mdi-check-box-outline</v-icon
          >
          <v-icon
            v-else-if="
              item.requiresSupervisorSignature && !item.supervisorSignatureId
            "
            >mdi-signature</v-icon
          >
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12" sm="7">
      <v-card
        v-if="selected"
        flat
        outlined
        min-height="75vh"
        width="100%"
        height="100%"
      >
        <iframe width="100%" height="90%" :src="pdfFile"></iframe>
        <v-card-actions v-if="showSignaturePad" class="justify-center">
          <signature-pad @saveSignature="saveSignature"></signature-pad>
        </v-card-actions>
      </v-card>
      <v-card
        v-else
        width="100%"
        height="100%"
        min-height="75vh"
        flat
        outlined
        class="d-flex align-center justify-center"
        ><v-card-title>{{
          notes.length > 0 ? "Select an item to view" : "No notes to view"
        }}</v-card-title></v-card
      >
    </v-col>
  </v-row>
</template>

<script>
import SignaturePad from "../Shared/signature-pad.vue";
import DownloadZipButton from "../Shared/download-zip-btn";
export default {
  components: { SignaturePad, DownloadZipButton },
  props: ["clientId", "isServiceCoordination", "notes"],

  data() {
    return {
      headers: [
        {
          text: "Provider Name",
          value: "providerName",
        },
        {
          text: "Date",
          value: "formattedDateOfService",
          hide: this.isServiceCoordination && Boolean(this.clientId), // it's the group header
        },
        {
          text: "Client Name",
          value: "clientName",
          hide: Boolean(this.clientId),
        },
        {
          text: "Service",
          value: "serviceName",
          hide: this.isServiceCoordination || Boolean(this.clientId), // all same service || group header
        },
        {
          text: "Signed",
          value: this.isServiceCoordination
            ? "scSignatureId"
            : "supervisorSignatureId",
        },
      ],
      search: "",
      pdfFile: "",
      selected: null,
      selectedItems: [],
      unsignedOnly: false,
    };
  },
  computed: {
    _headers() {
      return this.headers.filter((x) => !x.hide);
    },
    _groupBy() {
      return this.clientId
        ? this.isServiceCoordination
          ? "dateOfService"
          : "serviceId"
        : "clientName";
    },
    providerId() {
      return this.$store.state.sharedModule.providerId;
    },
    _notes() {
      return this.unsignedOnly // if unsignedOnly -> return notes that require signature (based on the note type)
        ? this.isServiceCoordination
          ? this.notes.filter((item) => !item.scSignatureId)
          : this.notes.filter(
              (item) =>
                item.requiresSupervisorSignature && !item.supervisorSignatureId
            )
        : this.notes; // else -> return the notes passed in as props
    },
    showSignaturePad() {
      // if note requires the current user's signature
      return this.isServiceCoordination
        ? this.selected.providerId === this.providerId &&
            !this.selected.scSignatureId
        : this.selected.supervisorId === this.providerId &&
            !this.selected.supervisorSignatureId;
    },
    zipFileName: function () {
      return this.isServiceCoordination
        ? "Service_Coordination_Notes.zip"
        : "Session_Notes.zip";
    },
  },
  methods: {
    async loadFile(item) {
      this.selected = item;
      var fileUrl = await this.$store.dispatch("filesModule/getFileUrl", {
        fileId: this.isServiceCoordination
          ? item.scNoteFileId
          : item.sessionNoteFileId,
      });
      this.pdfFile = fileUrl;
    },
    highlightSelectedRow: function (row) {
      return this.isServiceCoordination
        ? this.selected?.serviceCoordinationNoteId ===
            row.serviceCoordinationNoteId && "selectedRow"
        : this.selected?.sessionNoteId === row.sessionNoteId && "selectedRow";
    },
    clearSelected() {
      this.selectedItems = [];
    },
    async saveSignature(consentedAt, data) {
      this.$store.commit("uxModule/setShowLoader", true);
      if (this.isServiceCoordination) {
        var response = await this.$store.dispatch(
          "sessionsModule/signServiceCoordinationNote",
          {
            serviceCoordinationNoteId: this.selected.serviceCoordinationNoteId,
            consentedAt: consentedAt,
            signatureURI: data,
          }
        );
      } else {
        var response = await this.$store.dispatch(
          "sessionsModule/signSessionNote",
          {
            sessionNoteId: this.selected.sessionNoteId,
            consentedAt: consentedAt,
            signatureURI: data,
          }
        );
      }
      this.$store.commit("uxModule/setShowLoader", false);
      if (response.success) {
        this.loadFile(response.data);
      }
      if (!response.success) {
        this.$store.commit("uxModule/setSnackbarMsg", "Error signing note");
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
    },
  },
  watch: {
    notes() {
      this.selected = null;
    },
  },
};
</script>
<style lang="css">
.selectedRow {
  background: #ccc;
}
</style>